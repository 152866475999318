// eslint-disable-next-line no-restricted-syntax
import { Sprite, Texture } from 'pixi.js';

type GradientStep = {
  percent: number;
  value: string;
};

type GradientSettings = {
  steps: GradientStep[];
  quality: number;
};

const defaultGratientSettings: GradientSettings = {
  quality: 1024,
  steps: [
    { percent: 0, value: '#000000' },
    { percent: 1, value: '#ffffff' },
  ],
};

export class GradientSprite extends Sprite {
  constructor(settings: Partial<GradientSettings>) {
    super(Texture.EMPTY);
    const canvas = document.createElement('canvas');
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;

    const options = { ...defaultGratientSettings, ...settings };

    canvas.width = options.quality;
    canvas.height = 1;

    const canvasGradient = ctx.createLinearGradient(0, 0, options.quality, 0);
    options.steps.forEach((s) => canvasGradient.addColorStop(s.percent, s.value));

    ctx.fillStyle = canvasGradient;
    ctx.fillRect(0, 0, options.quality, 1);

    this.texture = Texture.from(canvas);
  }
}
